<template>
  <div>
    <!-- 模块1 -->
    <div class="h-477 dis-flex a-i-c j-c-c new-model1" :style="newModel1">
      <h3 class="fs-48 c-fefefe fw-b">{{ $g("newBG.title") }}</h3>
    </div>
    <ul class="pd-30">
      <li
        class="dis-flex j-c-b a-i-c fs-24 mb-40"
        v-for="(item, index) in articleList || []"
        :key="index"
        @click="toDetail(item)"
      >
        <div>
          <img class="w-220 h-130 obiect-c va-m" :src="item.image1" alt="" />
        </div>
        <div class="flex-1 ml-20 text-td">
          <h3 class="fs-28 fw-b text-td">{{ item.title }}</h3>
          <p class="c-6 mt-15 text-td">{{ item.memo }}</p>
          <div class="dis-flex j-c-b mt-15">
            <span class="c-9">{{ item.ext1 }}</span>
            <span class="c-main bb-2-main">{{ $g("newOther.title") }}</span>
          </div>
        </div>
      </li>
    </ul>
    <ul class="dis-flex j-c-c fs-28 pb-40 new-page">
      <li
        :class="[
          page == 1 ? 'dis-no' : '',
          'bg-main c-f plr-40 h-50 dis-flex j-c-c a-i-c mlr-10',
        ]"
        @click="prevClick"
      >
        上一页
      </li>
      <template v-for="(item, index) in pageTotal - 1">
        <li
          :class="[
            'w-50 h-50 dis-flex j-c-c a-i-c mlr-10',
            page == item ? 'c-main bd-main' : 'c-b1b1b1 bd-b1b1b1',
          ]"
          v-if="item < size || page == item || page > item"
          :key="index"
          @click="pageClick(item)"
        >
          {{ item }}
        </li>
      </template>
      <li
        v-if="pageTotal > 5 && page != pageTotal - 1 && page != pageTotal"
        class="w-50 h-50 dis-flex j-c-c a-i-c mlr-10 c-b1b1b1 bd-b1b1b1"
      >
        ...
      </li>
      <li
        :class="['w-50 h-50 dis-flex j-c-c a-i-c mlr-10', page == pageTotal ? 'c-main bd-main' : 'c-b1b1b1 bd-b1b1b1', ]"
        @click="pageClick(pageTotal)"
      >
        {{ pageTotal }}
      </li>
      <li
        :class="['bg-main c-f plr-40 h-50 dis-flex j-c-c a-i-c mlr-10', page == pageTotal ? 'dis-no' : '',]"
        @click="nextClick"
      >
        下一页
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "New",
  data() {
    return {
      page: 1,
      size: 4,
    };
  },
  methods: {
    prevClick() {
      if (this.page == 1) return;
      this.page -= 1;
    },
    pageClick(i) {
      this.page = i;
    },
    nextClick() {
      if (this.page == this.pageTotal) return;
      this.page += 1;
    },
    toDetail(item) {
      this.$router.push({ name: "NewDetail", query: { id: item.id } });
    },
  },
  computed: {
    pageTotal() {
      let me = this;
      let total = me.$a.newArticle ? me.$a.newArticle.length : 1;
      return Math.ceil(total / me.size);
    },
    articleList() {
      let me = this;
      let index = me.page - 1;
      let start = index * me.size;
      let end = index * me.size + me.size;
      return me.$a.newArticle ? me.$a.newArticle.slice(start, end) : [];
    },
    newModel1() {
      let me = this;
      return `
       background-image: url(${me.$t.newBG ? me.$t.newBG.image1 : ""});
       background-repeat: no-repeat;
       background-attachment:center;
       background-size:cover;`;
    },
  },
};
</script>
<style lang="scss">
.new-model1 {
  background: url(/static/images/new/model1-img.jpg) no-repeat center;
  background-size: cover;
}
.new-page .dis-no {
  opacity: 0.36;
}
</style>
