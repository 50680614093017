<template>
    <div>
        <!-- 模块1 -->
        <div class="h-477 dis-flex a-i-c j-c-c aboutus-model1">
            <h3 class="fs-48 fw-b c-f">{{$g('aboutsModel1.title')}}</h3>
        </div>
        <!-- 模块2 -->
        <div class="pd-40 bg-f7f7f7">
            <div class="dis-flex j-c-b">
                <h3 class="fs-34 fw-b">
                    <span>{{$g('aboutsModel21.title')}}</span>
                    <p class="lh-0"><i class="dis-ib w-70 h-5 bg-main"></i></p>
                </h3>
                <img class="w-284 h-143" src="/static/images/aboutus/model2-bg.png" alt="">
            </div>
            <p class="fs-28 c-3 lh-34 mt-40">{{$g('aboutsModel21.content')}}</p>
            <div class="mt-20" v-if="$g('aboutsModel21.image1')"><img class="w-p100" :src="$g('aboutsModel21.image1')" alt=""></div>
            <ul v-else class="dis-flex mt-100">
                <li :class="['flex-1 txt-c', index==1?'plr-30':'']" v-for="item,index in $t.aboutsModel22" :key="index">
                    <h3 class="fs-52 c-main fw-b">{{item.title}}</h3>
                    <p class="fs-24 c-3 mt-25">{{item.content}}</p>
                </li>
            </ul>
        </div>
        <!-- 模块3 -->
        <div class="pt-90 pb-50 aboutus-model3">
            <div class="pr-20 dis-flex j-c-b a-i-c">
                <div class="aboutus-model3-img"><img class="w-267 va-m" :src="$g('aboutsModel31.image1')" alt=""></div>
                <div class="ml-15 flex-1 txt-r">
                    <h3 class="fs-34 fw-b c-3">
                        <span>{{$g('aboutsModel31.title')}}</span>
                        <p class="lh-0"><i class="dis-ib w-60 h-7 bg-main"></i></p>
                    </h3>
                    <p class="fs-28 c-3 lh-36 mt-40">{{$g('aboutsModel31.content')}}</p>
                </div>
            </div>
            <div class="mt-100" v-if="$g('aboutsModel31.image2')"><img class="w-p100" :src="$g('aboutsModel31.image2')" alt=""></div>
            <ul v-else class="mt-100 por dis-flex j-c-a aboutus-model3-list">
                <li v-for="(item,index) in $t.aboutsModel32 || []" :key="index" class="txt-c plr-20 flex-1" :style="{opacity: JSON.stringify((index + 1 )* 0.1 + 0.5) }">
                    <p class="fs-28 c-main fw-b">{{item.content}}</p>
                    <p class="lh-0 mt-10"><i class="dis-ib va-m por bg-main around"></i></p>
                    <p class="c-3 fs-24 mt-10">{{item.title}}</p>
                </li>
            </ul>
        </div>
        <!-- 模块4 -->
        <div class="dis-flex a-i-c mt-30 plr-40 c-f h-218 aboutus-model4">
            <h3 class="fs-34 fw-b">
                <span>{{$g('aboutsModel4.title')}}</span>
                <p class="lh-0"><i class="dis-ib w-130 h-5 bg-main"></i></p>
            </h3>
        </div>
        <!-- 模块5 -->
        <div class="mt-70 por">
            <h3 class="fs-28 fw-b c-0 plr-40">{{$g('aboutsModel5.title')}}<span class="ml-20">{{$g('aboutsModel5.content')}}</span>  </h3>
            <ul class="dis-flex j-c-b mt-40">
                <li class="mt-50 flex-1">
                    <p class="lh-30 fs-28 c-0 pl-40" v-html="$g('aboutsModel5.ext1')"></p>
                    <div class="dis-flex mt-45 pl-70 pt-15 pb-15 a-i-center bg-main c-f aboutus-model5-bottom">
                        <img class="w-70 h-70" :src="$g('aboutsModel5.image2')" alt="">
                        <p class="txt-c ml-20 fs-24 lh-30" v-html="$g('aboutsModel5.image2Tip')"></p>
                    </div>
                    <p class="fw-b aboutus-model5-title">{{$g('aboutsModel5.title')}}</p>
                </li>
                <li class="pr-40"><img class="w-343 h-400 va-m" :src="$g('aboutsModel5.image1')"></li>
            </ul>
        </div>
        <!-- 模块6 -->
        <div class="mt-90 por ov-h">
            <h3 class="fs-28 fw-b c-0 plr-40 txt-r">{{$g('aboutsModel6.title')}}<span class="ml-20">{{$g('aboutsModel6.content')}}</span>  </h3>
            <ul class="dis-flex j-c-b mt-40">
                <li class="pl-40 aboutus-model6-img"><img class="w-343 h-400 va-m" :src="$g('aboutsModel6.image1')"></li>
                <li class="mt-70 flex-1 lh-30 fs-28">
                    <div class="pl-50" v-html="$g('aboutsModel6.ext1')"></div>
                    <div class="pl-50" v-html="$g('aboutsModel6.ext2')"></div>
                    <p class="fw-b aboutus-model6-title">{{$g('aboutsModel6.title')}}</p>
                    <div class="dis-flex mt-20 pl-70 pt-15 pb-15 a-i-center bg-main c-f aboutus-model6-bottom">
                        <img class="w-70 h-70" :src="$g('aboutsModel6.image2')" alt="">
                        <p class="txt-c ml-20 fs-24 lh-30" v-html="$g('aboutsModel6.image2Tip')"></p>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 模块7 -->
        <div class="mt-100 pb-5 pt-60 txt-c pl-40 pr-40 aboutus-model7">
            <h3 class="fs-34 c-f fw-b txt-c mb-50">
                <span class="por aboutus-model7-title">{{$g('aboutsModel71.title')}}</span>
            </h3>
            <p class="txt-c fs-24 c-f w-500 mlr-auto">{{$g('aboutsModel71.content')}}</p>
            <div v-if="$g('aboutsModel71.image2')" class="mt-70 aboutus-model7-bottom"><img class="w-p100" :src="$g('aboutsModel71.image2')" alt=""></div>
            <ul v-else class="txt-c por mt-70 aboutus-model7-bottom">
                <li class="lh-0"><img class="w-p100 br-10 object-c" :src="$g('aboutsModel71.image1')" alt=""></li>
                <li class="c-f dis-flex flex-column a-i-c j-c-c plr-30 aboutus-model7-list">
                    <h3 class="fs-28">{{$g('aboutsModel72.title')}}</h3>
                    <ul class="dis-flex fs-24 flex-wrap j-c-b mt-20">
                        <li class="mt-5 w-96 ml-5 mr-5" v-for="item,index in $t.aboutsModel73 || []" :key="index">{{item.title}}</li>
                    </ul>
                </li>
            </ul>
        </div>
        <!-- 模块8 -->
        <div class="pt-200 pb-60 mlr-40">
            <h3 class="c-3 fs-34 fw-b">{{$g('aboutsModel81.title')}}</h3>
            <p class="lh-0"><i class="dis-ib h-7 w-65 bg-main"></i></p>
            <p class="fs-24 c-3 mt-20">{{$g('aboutsModel81.content')}}</p>
            <ul class="mt-40 dis-flex j-c-a flex-wrap">
                <li class="bg-edf1f5 txt-c br-10 w-325 mb-30" v-for="item,index in $t.aboutsModel82 || []" :key="index">
                    <div class="bg-main h-88 br-tr-10 dis-flex a-i-c j-c-c"><img class="w-42" :src="item.image1" alt=""></div>
                    <h3 class="c-3 fs-26 fw-b plr-25 mt-15">{{item.title}}</h3>
                    <p class="fs-24 c-9 mt-20 pb-15 pl-20 pr-20">{{item.content}}</p>
                </li>
            </ul>
        </div>
        <!-- 模块9 -->
        <div class="pt-60 pl-40 pr-40 pb-60 bg-f7f7f7" id="friends">
            <h3 class="c-3 fs-34 fw-b">{{$g('aboutsModel91.title')}}</h3>
            <p class="mt-10 lh-0"><i class="dis-ib h-7 w-60 bg-main"></i></p>
            <div class="dis-flex mt-50">
                <div class="w-140">
                    <ul class="w-140 bg-main c-f txt-c dis-flex flex-column pd-20" >
                        <li :class="[index==0?'pt-20':'mt-50']" v-for="item,index in $t.aboutsModel92 || []" :key="index">
                            <p class="fs-36 fw-b">{{item.content}}</p>
                            <p class="fs-24 mt-5">{{item.title}}</p>
                        </li>
                    </ul>
                </div>
                <ul class="flex-1 dis-flex flex-wrap">
                    <li class="ml-10 mb-10 w-95 h-95 dis-flex j-c-center a-i-center bg-f" v-for="item,index in $t.aboutsModel93 || []" :key="index"><img class="w-95" :src="item.image1" alt=""></li>
                </ul>
            </div>
        </div>
        <!-- 模块10 -->
        <div class="pt-90 pl-40 pr-40 aboutus-model10">
            <h3 class="fs-34 fw-b c-3 txt-r">
                <span>{{$g('aboutsModel101.title')}}</span>
                <p class="lh-0"><i class="dis-ib w-70 h-7 bg-main"></i></p>
            </h3>
            <div class="fs-28 c-6 mt-40 txt-r" v-html="$g('aboutsModel101.content')"></div>
            <div class="mt-50"><img class="w-p100" :src="$g('aboutsModel101.image1')" alt=""></div>
            <ul class="mt-30 txt-c c-f aboutus-model10-list">
                <li class="bg-main bb-1-f pd-20" v-for="item,index in $t.aboutsModel102 || []" :key="index">
                    <h3 class="fs-28 fw-b">{{item.title}}</h3>
                    <p class="fs-24 mt-15">{{item.content}}</p>
                </li>
            </ul>
            <div class="pt-150 pb-150 fs-24 fw-b c-main txt-c" v-html="$g('aboutsModel101.ext1')"></div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Aboutus",
        data() {
            return {
            };
        },
        mounted(){
            if( this.$route.query.id == 'friends'){
                document.getElementById('friends').scrollIntoView();
            }
        }
    }
</script>

<style lang="scss">
    .aboutus-model1 {
        background: url('/static/images/aboutus/model1-bg.jpg') no-repeat center;
        background-size: cover;
    }
    .aboutus-model3 {
        background: url('/static/images/aboutus/model3-bg.jpg') no-repeat center;
        background-size: cover;
        .aboutus-model3-img{
            margin-left: -50px;
        }
        .aboutus-model3-list{
            z-index: 1;
            &::after{
                content: "";
                position: absolute;
                left: 0;
                top: 56px;
                height: 8px;
                right: 0;
                z-index: -1;
                transform: rotate(180deg);
                background: linear-gradient(89deg, #B02125, #C79090);
            }
        }
        .aboutus-model3-list .around{
            width: 23px;
            height: 23px;
            border: 1px solid #B02125;
            border-radius: 50%;
            background: #fff;
        }
        .aboutus-model3-list .around::after{
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -5px;
            margin-left: -5px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #B02125;
        }
    }
    .aboutus-model4 {
        background: url('/static/images/aboutus/model4-bg.jpg') no-repeat center;
        background-size: cover;
    }
    .aboutus-model5-title {
        position: absolute;
        left: 40px;
        bottom: 150px;
        z-index: -1;
        font-size: 111px;
        color: rgba(193, 59, 59, 0.07);
    }
    .aboutus-model5-bottom {
        margin-right: -50px;
    }
    .aboutus-model6-title {
        position: absolute;
        right: -10px;
        bottom: 150px;
        z-index: -1;
        font-size: 111px;
        color: rgba(193, 59, 59, 0.07);
    }
    .aboutus-model6-img{z-index: 1;}
    .aboutus-model6-bottom {
        margin-left: -50px;
    }
    .aboutus-model7 {
        background: url('/static/images/aboutus/model7-bg.jpg') no-repeat center;
        background-size: cover;
        .aboutus-model7-bottom {
            margin-bottom: -100px;
        }
    }
    .aboutus-model7-title::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 133px;
        height: 7px;
        background: #B02125;
    }
    .aboutus-model7-list {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 338px;
        background: #AC0000;
        opacity: 0.88;
    }
    .aboutus-model10 {
        background: url('/static/images/aboutus/model10-bg.png') no-repeat center bottom;
        background-size: 100%;
        .aboutus-model10-list {
            box-shadow: 0px 0px 38px 0px rgba(173, 45, 45, 0.15);
        }
    }
</style>