<template>
    <div>
        <!-- 模块1 - 大图 -->
        <div class="dis-flex a-i-c j-c-c h-477 joinus-model1">
            <h3 class="c-f fs-48 fw-b">{{$g('joinUsModel1.title')}}</h3>
        </div>
        <!-- 模块2 -->
        <div class="mt-60">
            <h3 class="fs-34 c-3 fw-b pl-20">
                <span>{{$g('joinUsModel2.title')}}</span>
                <p class="lh-0"><i class="dis-ib w-70 h-7 bg-main va-m"></i></p>
            </h3>
            <ul class="dis-flex j-c-b mt-40 pl-20">
                <li>
                    <h3 class="c-main fs-72 fw-b">{{$g('joinUsModel2.ext1')}}</h3>
                    <p class="fs-24 c-6 mt-15">{{$g('joinUsModel2.ext2')}}</p>
                </li>
                <li><img class="va-m joinus-model2-img" :src="$g('joinUsModel2.image1')" alt=""></li>
            </ul>
            <div class="bg-main c-f pt-85 pl-40 pr-40 pb-85 fs-24 ck-content" v-html="$g('joinUsModel2.bigtext')"></div>
        </div>
        <!-- 模块3 -->
        <div class="pt-90 pr-40 pl-40 c-f pb-150 joinus-model3">
            <h3 class="fs-34 fw-b">
                <span>{{$g('joinUsModel3.title')}}</span>
                <p class="lh-0"><i class="dis-ib bg-f h-7 w-65"></i></p>
            </h3>
            <div class="fs-24 mt-60" v-html="$g('joinUsModel3.content')"></div>
        </div>
        <div v-if="$g('joinUsModel3.ext1')!='nonext'" class="joinus-model4">
            <ul class="ml-35 mr-35 plr-20 bg-main fs-30 fw-b c-f br-tr-10 h-75 dis-flex a-i-c j-c-c">
                <li :class="[index==0?'w-200':'flex-1  txt-c']" v-for="item,index in $t.joinUsModel4Head" :key="index">{{item.title}}</li>
            </ul>
            <div class="bg-f3f3f3 pr-35 pl-35 pt-20 pb-20 fs-24">
                <ul class="bg-f plr-20 dis-flex a-i-c j-c-c h-80 br-10 mb-20" v-for="item,index in $t.joinUsModel4Data || []" :key="index" @click="toDetail(item,index)">
                    <li class="fw-b w-200">{{item.title}}</li>
                    <li class="c-6 flex-1 txt-c">{{item.content}}</li>
                    <li class="c-6 flex-1 txt-c">{{item.ext1}}</li>
                    <li class="c-0090ff flex-1 txt-c">{{item.ext2}}</li>
                </ul>
                <div class="c-main">{{$g('joinMore.title')}}</div>
           </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Joinus",
        methods: {
            toDetail(item,index){
                this.$router.push({ path: '/joinus/detail',query:{index:index}})
            }
        }
    }
</script>
<style lang="scss" scoped>
    .joinus-model1 {
        background: url(/static/images/joinus/model1-bg.jpg) no-repeat center;
        background-size: cover;
    }
    .joinus-model2-img {
        width: 523px;
        height: 238px;
    }
    .joinus-model3 {
        background: url(/static/images/joinus/model3-bg.jpg) no-repeat center;
        background-size: cover;
    }
    .joinus-model4 {
        margin-top: -75px;
    }
</style>