<template>
  <div class="mb-70">
    <div
      class="dis-flex a-i-c j-c-c h-477 business-model1"
      :style="businessModel1"
    >
      <h3 class="fs-48 fw-b c-f">{{ $g("businessBG.title") }}</h3>
    </div>
    <div class="mt-52 txt-c">
      <h3 class="fs-34 c-3">{{ $g("businessListTitle.title") }}</h3>
      <div class="mt-4 lh-0"><i class="dis-ib w-68 h-7 bg-main"></i></div>
      <p class="fs-24 c-87 mt-36">{{ $g("businessListTitle.content") }}</p>
      <div
        v-for="(it, index) in $t.businessListContent || []"
        :key="index"
        class="mt-50"
        @click="toDetail(index)"
      >
        <div class="mlr-40   br-10 bg-edf1f5 dis-flex flex-column">
          <img class="br-tr-10 h-325 object-c" :src="it.image1" />
          <p class="c-606464 pl-50 pr-50 pt-20 pb-20 flex-1 dis-flex a-i-c fs-26 j-c-c">
            {{ it.content }}
          </p>
        </div>
        <div class="mt-20 fs-30 c-313848 fw-500 txt-c">{{ it.title }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Business",
  data() {
    return {};
  },
  methods: {
    toDetail(index) {
      this.$router.push({ path: `/business/detail/${index}` });
    },
  },
  computed: {
    businessModel1() {
      let me = this;
      return `
       background-image: url(${me.$g("businessBG.image1")});
       background-repeat: no-repeat;
       background-attachment:center;
       background-size:cover;`;
    },
  },
};
</script>
<style lang='scss'>
.business-model1 {
  background: url("/static/images/business/1.png") no-repeat center;
  background-size: cover;
}
</style>