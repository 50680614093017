<template>
  <div>
    <!-- 模块1 -->
    <div class="dis-flex a-i-c j-c-c h-477 contactus-model1">
        <h3 class="fs-48 fw-b c-f">{{$g('contactUsModel1.title')}}</h3>
    </div>
    <!-- 模块3 -->
    <div class="mt-80 plr-40">
        <h3 class="fs-34 c-3 fw-b">
            <span>{{$g('contactUsModel2.title')}}</span>
            <p class="lh-0 mt-10"><i class="dis-ib w-65 h-7 bg-main va-m"></i></p>
        </h3>
        <p class="mt-40 c-9 fs-24">{{$g('contactUsModel2.content')}}</p>
        <div class="mt-90 por contactus-model2-img"><img class="w-p100 va-m" src="/static/images/contactus/model2-img.png" alt=""></div>
        <p class="mt-50 fs-30 fw-b c-6 txt-c">{{$g('contactUsModel3.title')}}</p>
        <ul class="mt-20">
          <li class="mb-10"><input v-model="cdata.name" class="h-70 w-p100 bg-f4f4f4 plr-40 fs-28 br-n input" type="text" :placeholder="$g('contactUsModel3.ext1')"/></li>
          <li class="mb-10"><input v-model="cdata.email" class="h-70 w-p100 bg-f4f4f4 plr-40 fs-28 br-n input" type="text" :placeholder="$g('contactUsModel3.ext2')"/></li>
          <li class="mb-10"><input v-model="cdata.title" class="h-70 w-p100 bg-f4f4f4 plr-40 fs-28 br-n input" type="text" :placeholder="$g('contactUsModel3.ext3')"/></li>
          <li class="mb-10"><textarea v-model="cdata.content" class="pl-40 pr-40 pt-20 h-272 w-p100 bg-f4f4f4 bd-n resize-n br-n fs-28" :placeholder="$g('contactUsModel3.ext4')"></textarea></li>
          <li class="dis-flex j-c-e a-i-c mt-20">
              <span v-if="showTip" class="fs-28 c-main mr-30">{{$g('contactUsModel3.content')}}</span>
              <span class="dis-flex w-200 h-55 a-i-c j-c-c fs-28 bg-main c-f"  @click="submit">{{$g('contactUsModel3.ext5')}}</span>
          </li>
        </ul>
    </div>
    <!-- 模块4 -->
    <div class="pd-40">
        <h3 class="c-3 fs-34 c-3">{{ $g('contactUsModel4.title') }}</h3>
        <p class="lh-0"><i class="dis-ib h-7 w-65 bg-main mt-10"></i></p>
        <!-- 地址 -->
        <ul class="mt-30">
          <li class="dis-flex">
            <span
              :class="[
                'dis-flex h-60 txt-c plr-60 a-i-c mr-25 fs-30 mr-60',
                cityindex == index ? 'bg-main c-f' : 'bg-e4e4e4 c-a1a1a1',
              ]"
              @click="cityindex = index"
              v-for="(item, index) in $t.contactUsModel5"
              :key="index"
              >{{ item.title }}</span
            >
          </li>
          <li class="dis-flex j-c-s mt-20">
              <img class="w-25 h-30 mt-5 mr-25" 
                :src="$g('contactUsModel51.image1')" alt="" />
            <span class="fs-28 c-3 w-b-all">{{ $t.contactUsModel5? $t.contactUsModel5[cityindex].content : '' }}</span>
          </li>
          <li class="dis-flex j-c-s mt-20">
              <img class="w-30 h-21 mt-8 mr-20" 
                  :src="$g('contactUsModel51.image2')" alt="" />
            <span class="fs-28 c-3 w-b-all">{{ $t.contactUsModel5 ? $t.contactUsModel5[cityindex].ext5 : '' }}</span>
          </li>
          <li class="mt-20">
            <img class="w-p100 object-c" :src="$t.contactUsModel5 ? $t.contactUsModel5[cityindex].image1 : ''" alt="" />
          </li>
        </ul>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: "Contactus",
  data() {  
    return {
      cityindex: 0,
      showTip:false,
      cdata:{name:"",email:"",title:"",content:"",source:""},
    };
  },
  methods: {
    async submit(){
      let me = this;
      try{
        if ( !me.cdata.name ||!me.cdata.email ){
          return;
        }
        me.cdata.source = "app-"+me.$app.curLan.name;
        await axios.post("/data/gwfeedback/add",me.cdata);
        me.showTip = true;
        setTimeout(function(){
          me.cdata = {name:"",email:"",title:"",content:"",source:""}
          me.showTip = false;
        },3000)
      }catch(e){
        console.log(e)
      }
    }
  },
};
</script>
<style lang="scss">
  .contactus-model1 {
    background: url('/static/images/contactus/model1-bg.jpg') no-repeat center;
    background-size: cover;
  }
  .contactus-model2-img{
    &::after{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 20px;
      top: 20px;
      background: #b02125;
      box-shadow: 0px 0px 16px 0px rgba(173, 45, 45, 0.45);
      border-radius: 55px 0px 0px 0px;
      z-index: -1;
    }
  }
  .input {
    outline: none;
  }
  .input::-webkit-input-placeholder{color: #666;}
</style>