<template>
    <div>
        <template v-for="(item, index) in $t.businessListDetailUp">
            <div v-if="navIndex == index" :key="index" class="por h-477">
                <img class="w-p100 h-p100 object-c" :src="item.image1" alt="" />
                <h3 class="fs-48 c-f fw-b dis-flex a-i-c j-c-c business-detail-title">
                {{ $g('businessListDetailTitle.title') }}
                </h3>
                <ul class="dis-flex a-i-c flex-wrap pl-30 pt-30 pb-20 pr-25 fs-24 c-9 business-detail-nav">
                    <li class="ml-5 mt-5 w-220 h-80 txt-c cp" v-for="(item, index) in $t.businessListDetailUp" :key="index" @click="navClick(index)">
                        <div v-if="item.image2 || item.image2Tip">
                            <img class=" w-220" :src="navIndex == index ? item.image2Tip : item.image2" alt="">
                        </div>
                        <div v-else
                            :class="[
                            'plr-15 h-p100 w-p100 bg-f dis-flex flex-wrap a-i-c j-c-c',
                            navIndex == index ? 'c-f bg-main active' : '',
                            ]" 
                            v-html="item.title"></div>
                    </li>
                </ul>
            </div>
        </template>
        <!-- navIndex==0 -->
        <ul v-if="navIndex==0" class="pt-35">
            <li class="pt-70 pl-40 pr-40 por">
                <div v-if="$t.businessListDetailUp && $t.businessListDetailUp[0].image3"><img class="w-330" :src="$t.businessListDetailUp[0].image3" alt=""></div>
                <h3 v-else class="fs-30 c-0 fw-b w-330 h-130 bg-f0f3f7 dis-flex flex-wrap a-i-c j-c-c por plr-50 txt-c detail-title" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[0].title : ''"></h3>
                <div class="dis-flex ov-h w-360 h-156 a-i-e detail-model1-img">
                    <img class="w-360 h-360 br-half" :src="$t.businessListDetailUp ? $t.businessListDetailUp[0].ext2 : ''" alt="">
                </div>
                <div class="pt-60 pb-100 c-3 fs-28 ck-content" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[0].bigtext : ''"></div>
            </li>
            <li class="pd-40 detail1-model2">
                <h3 class="fs-34 c-f fw-b">{{$g('businessListDetailDown1Title.title')}}</h3>
                <ul class="mt-50 dis-flex flex-wrap j-c-a">
                    <li class="w-330 br-10 ptb-20 txt-c bg-f7f9fa dis-flex flex-column a-i-c j-c-c mb-15" v-for="item,index in $t.businessListDetailDown1List" :key="index">
                        <div class="lh-0"><img class="va-m w-50" :style="{width: (item.iconW/75)+ 'rem', height : (item.iconH/75) + 'rem'}" :src="item.image1" alt=""></div>
                        <p class="fs-24 c-3 mt-15 lh-30" v-html="item.title"></p>
                    </li>
                </ul>
            </li>
        </ul>
        <!-- navIndex==1 -->
        <ul v-if="navIndex==1" class="pt-35">
            <li class="pt-70 pl-40 pr-40 por">
                <div v-if="$t.businessListDetailUp && $t.businessListDetailUp[1].image3"><img class="w-330" :src="$t.businessListDetailUp[1].image3" alt=""></div>
                <h3 v-else class="fs-30 c-0 fw-b w-330 h-130 bg-f0f3f7 dis-flex flex-wrap a-i-c j-c-c por plr-50 txt-c detail-title" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[1].title : ''"></h3>
                <div class="dis-flex ov-h w-360 h-156 a-i-e detail-model1-img">
                    <img class="w-360 h-360 br-half" :src="$t.businessListDetailUp ? $t.businessListDetailUp[1].ext2 : ''" alt="">
                </div>
                <div class="pt-60 pb-100 c-3 fs-28 ck-content" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[1].bigtext : ''"></div>
            </li>
        </ul>
        <!-- navIndex==2 -->
        <ul v-if="navIndex==2" class="pt-35">
            <li class="pt-70 pl-40 pr-40 por">
                <div v-if="$t.businessListDetailUp && $t.businessListDetailUp[2].image3"><img class="w-330" :src="$t.businessListDetailUp[2].image3" alt=""></div>
                <h3 v-else class="fs-30 c-0 fw-b w-330 h-130 bg-f0f3f7 dis-flex flex-wrap a-i-c j-c-c por plr-50 txt-c detail-title" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[2].title : ''"></h3>
                <div class="dis-flex ov-h w-360 h-156 a-i-e detail-model1-img">
                    <img class="w-360 h-360 br-half" :src="$t.businessListDetailUp ? $t.businessListDetailUp[2].ext2 : ''" alt="">
                </div>
                <div class="pt-60 pb-100 c-3 fs-28 ck-content" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[2].bigtext : ''"></div>
            </li>
            <li class="h-350 dis-flex a-i-c plr-40 detail3-model2">
                <h3 class="h-100 plr-30 bg-main fs-30 fw-b c-f dis-flex a-i-c">{{$g('businessListDetailDown31.title')}}</h3>
            </li>
            <li class="dis-flex a-i-c pd-40 bg-main">
                <div class="fs-24 c-f" v-html="$g('businessListDetailDown32.content')"></div>
            </li>
            <li>
                <img class="w-p100 va-m" :src="$g('businessListDetailDown32.image1')" alt="">
            </li>
            <li class="h-350 dis-flex a-i-c j-c-c plr-40 detail3-model5">
                <h3 class="h-100 plr-90 bg-main fs-30 fw-b c-f dis-flex a-i-c">{{$g('businessListDetailDown33.title')}}</h3>
            </li>
            <li class="dis-flex a-i-c pl-40 pr-40 pt-90 pb-90 bg-main">
                <div class="fs-24 c-f" v-html="$g('businessListDetailDown34.content')"></div>
            </li>
            <li>
                <img class="w-p100 va-m" :src="$g('businessListDetailDown34.image1')" alt="">
            </li>
        </ul>
        <!-- navIndex==3 -->
        <ul v-if="navIndex==3" class="pt-35">
            <li class="pt-70 pl-40 pr-40 por">
                <div v-if="$t.businessListDetailUp && $t.businessListDetailUp[3].image3"><img class="w-330" :src="$t.businessListDetailUp?$t.businessListDetailUp[3].image3:''" alt=""></div>
                <h3 v-else class="fs-30 c-0 fw-b w-330 h-130 bg-f0f3f7 dis-flex flex-wrap a-i-c j-c-c por plr-50 txt-c detail-title" v-html="$t.businessListDetailUp?$t.businessListDetailUp[3].title||'':''"></h3>
                <div class="dis-flex ov-h w-360 h-156 a-i-e detail-model1-img">
                    <img class="w-360 h-360 br-half" :src="$t.businessListDetailUp ? $t.businessListDetailUp[3].ext2 : ''" alt="">
                </div>
                <div class="pt-60 pb-100 c-3 fs-28 ck-content" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[3].bigtext : ''"></div>
            </li>
            <li class="h-220 plr-40 dis-flex a-i-c detail4-model2">
                <h3 class="fs-34 fw-b c-f">{{ $g('businessListDetailDown41.title')}}</h3>
            </li>
            <li class="mt-50" v-if="$g('businessListDetailDown41.image1')"><img class="w-p100" :src="$g('businessListDetailDown41.image1')" alt=""></li>
            <li v-else class="pt-50 txt-c pb-40">
                <ul class="dis-ib pl-40 por detail4-model3">
                    <li
                        :class="['dis-ib va-m por', index == 1 ? 'mlr-70 index2' : '']"
                        v-for="(item, index) in $t.businessListDetailDown42"
                        :key="index"
                    >
                        <h3
                        :class="['fs-28 txt-l fw-b',
                            index == 0 ? 'c-1f54f4 w-140' : index == 1 ? 'c-fdc800 w-140' : 'c-0dcdbc w-170',
                        ]"
                        >
                        {{ item.title }}
                        </h3>
                        <div
                        :class="[
                            'mt-40 c-f por w-130 h-130 br-half dis-flex flex-column j-c-c index0',
                            index == 0
                            ? 'bg-1f54f4'
                            : index == 1
                            ? 'bg-fdc800'
                            : 'bg-0dcdbc',
                        ]"
                        >
                            <p class="fs-24 fw-b">{{ item.ext5 }}</p>
                            <p class="fs-24 cir-txt2">{{ item.content }}</p>
                        </div>
                    </li>
                    <li class="arrow">
                        <img
                         class="w-50 h-65 va-m"
                        src="/static/images/business/detail4/icon-top.png"
                        alt=""
                        />
                        <p class="w-120 txt-r fs-24 c-main">{{$g('businessListDetailDown43.ext1')}}</p>
                    </li>
                </ul>
                <div class="lh-0">
                    <img class="w-280 h-65 va-m" src="/static/images/business/detail4/icon-lr.png" alt="" />
                </div>
                <p class="fs-28 mb-15">{{ $g('businessListDetailDown43.title') }}</p>
                <ul class="dis-ib w-455 br-40 h-80 bg-main fs-24 c-f fs-18 por detail4-model3-bottom">
                    <li class="detail4-model3-bottom-l"></li>
                    <li
                        class="dis-ib pl-20 pr-20 pt-5"
                        v-for="(item, index) in $t.businessListDetailDown44 || []"
                        :key="index"
                    >
                        {{ item.title }}
                    </li>
                    <li class="detail4-model3-bottom-r"></li>
                </ul>
            </li>
        </ul>
        <!-- navIndex==4 -->
        <ul v-if="navIndex==4" class="pt-35">
            <li class="pt-70 pl-40 pr-40 por">
                <div v-if="$t.businessListDetailUp && $t.businessListDetailUp[4].image3"><img class="w-330" :src="$t.businessListDetailUp[4].image3" alt=""></div>
                <h3 v-else class="fs-30 c-0 fw-b w-330 h-130 bg-f0f3f7 dis-flex flex-wrap a-i-c j-c-c por plr-50 txt-c detail-title" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[4].title : ''"></h3>
                <div class="dis-flex ov-h w-360 h-156 a-i-e detail-model1-img">
                    <img class="w-360 h-360 br-half" :src="$t.businessListDetailUp ? $t.businessListDetailUp[4].ext2 : ''" alt="">
                </div>
                <div class="pt-60 pb-100 c-3 fs-28 ck-content" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[4].bigtext : ''"></div>
            </li>
            <li>
                <img class="w-p100 va-m" :src="$g('businessListDetailDown51.ext2')" alt="">
                <div class="dis-flex j-c-b">
                    <div class="flex-1 pt-75">
                        <h3 class="fs-34 c-3 fw-b pl-40">{{ $g('businessListDetailDown51.title') }}</h3>
                        <div class="bg-main mt-70 pl-40 pt-30 pb-30 fs-28 fw-b c-f detail5-model2-bottom" v-html="$g('businessListDetailDown51.content')"></div>
                    </div>
                    <div class="w-370 h-467 lh-0 dis-flex j-c-s ov-h detail5-model2-right"><img class="h-p100 va-m" :src="$g('businessListDetailDown51.ext4')" alt=""></div>
                </div>
            </li>
            <li class="mt-100">
                <img class="w-p100" :src="$g('businessListDetailDown52.image1')" alt="">
                <div class="pt-50 pl-40 pr-40 c-3 fw-b">
                    <h3 class="fs-34">{{$g('businessListDetailDown52.title')}}</h3>
                    <p class="mt-60 fs-28 txt-j">{{$g('businessListDetailDown52.content')}}</p>
                </div>
            </li>
            <li class="pd-40">
                <div class="mt-10 bg-main c-f fs-30 h-88 dis-flex a-i-c j-c-c" v-for="item,index in $t.businessListDetailDown53" :key="index">{{item.title}}</div>
            </li>
        </ul>
        <!-- navIndex==5 -->
        <ul v-if="navIndex==5" class="pt-35">
            <li class="pt-70 pl-40 pr-40 por">
                <div v-if="$t.businessListDetailUp && $t.businessListDetailUp[5].image3"><img class="w-330" :src="$t.businessListDetailUp[5].image3" alt=""></div>
                <h3 v-else class="fs-30 c-0 fw-b w-330 h-130 bg-f0f3f7 dis-flex flex-wrap a-i-c j-c-c por plr-50 txt-c detail-title" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[5].title : ''"></h3>
                <div class="dis-flex ov-h w-360 h-156 a-i-e detail-model1-img">
                    <img class="w-360 h-360 br-half" :src="$t.businessListDetailUp ? $t.businessListDetailUp[5].ext2 : ''" alt="">
                </div>
                <div class="pt-60 pb-100 c-3 fs-28 ck-content" v-html="$t.businessListDetailUp ? $t.businessListDetailUp[5].bigtext : ''"></div>
            </li>
            <li class="c-f pt-100 pl-40 pr-40 pb-100 detail6-model2">
                <h3 class="fs-30 fw-b" v-html="$g('businessListDetailDown61.title')"></h3>
                <p class="fs-24 mt-30">{{$g('businessListDetailDown61.content')}}</p>
            </li>
            <li class="plr-40 txt-c">
                <h3 class="fs-34 c-0 fw-b pt-60 pb-60">{{$g('businessListDetailDown62.title')}}</h3>
                <ul>
                    <li class="fs-28 br-10 c-3 pd-20 mb-25 bg-f0f4f6" v-for="item,index in $t.businessListDetailDown63 || []" :key="index">
                        <h4 class="fw-b">{{item.title}}</h4>
                        <p class="mt-20" v-html="item.content"></p>
                    </li>
                </ul>
                <div class="mlr-85 bg-main c-f fs-28 fw-b h-90 mt-20 dis-flex a-i-c j-c-c">{{$g('businessListDetailDown62.content')}}</div>
            </li>
            <li class="mt-60 pl-40 pr-40 pt-180 h-510 detail6-model4">
                <ul class="dis-flex flex-wrap j-c-a h-300 br-10 detail6-model4-list">
                    <li class="w-200 h-150 txt-c dis-flex flex-column j-c-c a-i-c" v-for="item,index in $t.businessListDetailDown64 || []" :key="index">
                        <div class="lh-0"><img class="w-40 va-m" :src="item.image1" alt=""></div>
                        <p class="fs-26 c-3 mt-10">{{item.title}}</p>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        name:"",
        data(){
            return {
                navIndex: this.$route.params.index || 0,
            }
        },
        watch:{
            $route(n){
                this.navIndex = n.params.index || 0
            }
        },
        methods: {
            navClick(index){
                if (this.$route.path === `/business/detail/${index}`) {
                    return
                }
                this.navIndex = index;
                this.$router.replace({path: `/business/detail/${index}`})
            }
        }
    }
</script>

<style lang="scss">
    .business-detail-title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin-top: -60px;
    }
    .business-detail-nav {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.1);
        .active {
            box-shadow: 0px 0px 13px 0px rgba(176, 33, 37, 0.57);
        }
    }
    .detail-title {
        &::after{
            content: "";
            position: absolute;
            left: 16px;
            top: 12px;
            width: 100%;
            height: 100%;
            border: 2px solid #B02125;
        }
    }
    .detail-model1-img {
        position: absolute;
        top: 0;
        right: 0;
        img {
            justify-content: flex-end;
        }
    }
    .detail1-model2 {
        background: url('/static/images/business/detail1/model2-bg.jpg') no-repeat center;
        background-size: cover;
    }
    .detail3-model2 {
        background: url('/static/images/business/detail3/model2-bg.jpg') no-repeat center;
        background-size: cover;
    }
    .detail3-model5 {
        background: url('/static/images/business/detail3/model5-bg.jpg') no-repeat center;
        background-size: cover;
    }
    .detail4-model2 {
        background: url('/static/images/business/detail4/model2-bg.jpg') no-repeat center;
        background-size: cover;
    }
    .detail4-model3 .index0{
        p{
            transform: scale(0.8);
        }
        .cir-txt2 {
            margin-top: -15px;
        }
    }
    .detail4-model3 .index0::before {
        content: "";
        position: absolute;
        top: -25px;
        right: -25px;
        left: -25px;
        bottom: -25px;
        background: inherit;
        opacity: 0.18;
        border-radius: 50%;
    }
    .detail4-model3 .index0::after {
        content: "";
        position: absolute;
        top: -15px;
        right: -15px;
        left: -15px;
        bottom: -15px;
        background: inherit;
        opacity: 0.4;
        border-radius: 50%;
        z-index: -1;
    }
    .detail4-model3 .arrow {
        position: absolute;
        left: -70px;
        bottom: -60px;
    }
    .detail4-model3-bottom{
        .detail4-model3-bottom-l {
            position: absolute;
            left: -6px;
            top: -30px;
            width: 17px;
            height: 17px;
            background: #BA2A2A;
            opacity: 0.8;
            border-radius: 50%;
        }
        .detail4-model3-bottom-r {
            position: absolute;
            right: -6px;
            top: -30px;
            width: 17px;
            height: 17px;
            background: #BA2A2A;
            opacity: 0.8;
            border-radius: 50%;
        }
        &::before{
            content: "";
            position: absolute;
            left: 0;
            width: 4px;
            height: 52px;
            top: -15px;
            color: #BA2A2A;
            opacity: 0.8;
            background: #BA2A2A;
        }
        &::after{
            content: "";
            position: absolute;
            right: 0;
            width: 4px;
            height: 52px;
            top: -15px;
            color: #BA2A2A;
            background: #BA2A2A;
        }
    }
    .detail5-model2-right {
        margin-top: -100px;
    }
    .detail5-model2-bottom {
        margin-right: -140px;
    }
    .detail6-model2 {
        background: url('/static/images/business/detail6/model2-bg.jpg') no-repeat center left;
        background-size: cover;
    }
    .detail6-model4 {
        background: url('/static/images/business/detail6/model4-bg.jpg') no-repeat center;
        background-size: cover;
    }
    .detail6-model4-list {
        background: rgba(255, 255, 255, 0.88);
    }
</style>