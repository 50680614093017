<template>
  <div class="bg-f">
    <!-- 模块1 -->
    <div class="por h-477">
      <img
        class="w-p100 h-p100 obiect-c"
        src="/static/images/joinus/detail-img.jpg"
        alt=""
      />
      <div
        class="bg-main br-tr-10 pl-30 pt-15 pr-30 pb-10 fs-26 c-f fw-b detail-top"
      >
        <p>{{ $g("joinUsDetail.title") }}:{{ detailData.title }}</p>
        <div class="dis-flex j-c-b">
          <span>{{ $g("joinUsDetail.content") }}:{{ detailData.content }}</span>
          <span>{{ $g("joinUsDetail.image1") }}:{{ detailData.ext1 }}</span>
        </div>
      </div>
    </div>
    <!-- 模块2 -->
    <div class="pd-40">
      <h3 class="fs-34 fw-b c-3 pb-10 bb-3-main">
        {{ $g("joinUsDetail.image2") }}({{ detailData.ext4 }}) :{{
          detailData.title
        }}
      </h3>
      <div class="c-6 lh-40 fs-28 ck-content" v-html="detailData.bigtext"></div>
    </div>
    <!-- 模块3 -->
    <div class="pt-30 pl-40 pb-20 pr-40 bg-f8f8f8">
      <h3 class="fs-30 fw-b c-main">{{ $g("joinUsDetail.ext1") }}</h3>
      <ul class="mt-30">
        <li
          class="mb-20 pd-20 dis-flex j-c-b bg-f bd-dash-d4d4d4-2"
          v-for="(item, index) in moreList"
          :key="index"
        >
          <div>
            <router-link
              :to="{
                path: '/joinus/detail',
                query: { index: curIndex + index + 1 },
              }"
            >
              <h4 class="fs-28 c-3 fw-b">{{ item.title }}</h4>
              <p class="fs-24 c-6 mt-10">{{ item.content }}</p>
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      curIndex: 0,
    };
  },
  computed: {
    detailData() {
      let me = this;
      let arr = me.$t.joinUsModel4Data || [];
      let index = me.$route.query ? me.$route.query.index : 0;
      console.log(index);
      console.log(arr[index]);
      return arr[index] || {};
    },
    moreList() {
      let me = this;
      if (!me.$t.joinUsModel4Data) {
        return [];
      }
      let index = me.$route.query ? me.$route.query.index : "0";
      me.curIndex = parseInt(index);
      if (me.$t.joinUsModel4Data.length - 1 == me.curIndex) {
        me.curIndex = -1;
      }
      let end = me.curIndex + 3;
      return me.$t.joinUsModel4Data.slice(me.curIndex + 1, end) || [];
    },
  },
};
</script>

<style lang="scss">
.detail-top {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 0;
}
</style>